
<template>
  <div class="px-5 mt-8" id="page-faith">
    <b-row>
      <b-col lg="2"></b-col>
      <b-col lg="8">
        <b-card title="" class="mb-2 mt-8" hide-footer>
          <b-form class="mt-8" @submit.prevent="saveData">
            <b-row>
              <b-col>
                <label class="checkbox checkbox-lg checkbox-outline checkbox-success mb-7">
                  <input
                    type="checkbox"
                    name=""
                    v-model="form.is_faith"
                    @click="form.is_faith = !form.is_faith"
                  />
                  <span class='mr-3'></span>
                  {{ $t('PAGES.SETTINGS.IS_FAITH') }}
                </label>
              </b-col>
            </b-row>
            
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-mem_pct"
                  :label="$t('PAGES.SETTINGS.MEM_PCT')"
                  label-for="input-mem_pct"
                >
                  <b-form-input
                    id="input-mem_pct"
                    v-model="form.mem_pct"
                    type="number" step="0.001"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-fee_pct"
                  :label="$t('PAGES.SETTINGS.FEE_PCT')"
                  label-for="input-fee_pct"
                >
                  <b-form-input
                    id="input-fee_pct"
                    v-model="form.fee_pct"
                    type="number" step="0.001"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-adm_fee"
                  :label="$t('PAGES.SETTINGS.ADM_FEE')"
                  label-for="input-adm_fee"
                >
                  <b-form-input
                    id="input-adm_fee"
                    v-model="form.adm_fee"
                    type="number" step="0.001"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-mem_fee"
                  :label="$t('PAGES.SETTINGS.MEM_FEE')"
                  label-for="input-mem_fee"
                >
                  <b-form-input
                    id="input-mem_fee"
                    v-model="form.mem_fee"
                    type="number" step="0.001"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-button type="submit" variant="primary">{{ $t('COMMON.SAVE') }}</b-button>
              </b-col>
            </b-row>
            

            
          </b-form>
        </b-card></b-col
      >
    </b-row>
  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import {
  SET_SETTINGS
} from '@/core/services/store/common.module';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import store from '@/core/services/store';

export default {
  name: 'faith-settings',
  props: ['settings'],
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {
      form: {
        is_faith: false,
        fee_pct: 0,
        adm_fee: 0,
        mem_fee: 0,
        mem_pct: 0
      }
    };
  },
  created(){
    if (this.settings && this.settings.is_faith){
      this.form.is_faith = true;
      this.form.fee_pct = this.settings.fee_pct;
      this.form.adm_fee = this.settings.adm_fee;
      this.form.mem_fee = this.settings.mem_fee;
      this.form.mem_pct = this.settings.mem_pct;
    }
  },
  methods: {
    stringToFloat(str) {
      let strdot = (str+'').replace(',','.');
      let num = parseFloat(strdot);

      if (isNaN(num)) {
        return 0;
      }

      return num;
    },
    saveData() {
      this.form.mem_fee = this.stringToFloat(this.form.mem_fee);
      this.form.mem_pct = this.stringToFloat(this.form.mem_pct);
      this.form.adm_fee = this.stringToFloat(this.form.adm_fee);
      this.form.fee_pct = this.stringToFloat(this.form.fee_pct);

      axios.put(`/company/company_settings/${this.currentCompanyId}`, this.form)
      .then(res => {
        if (res.status === 200) {
          store.dispatch(SET_SETTINGS, res.data);

          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        }
      })
      .catch(err => {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
      })
    }
  }
};
</script>
            