<template>
  <div class="px-5 mt-8" id="page-creditcard">
    <b-row>
      <b-col lg="2"></b-col>
      <b-col lg="8">
        <b-card title="" class="mb-2 mt-8" hide-footer>
          <div>
            <b-form class="mt-8" @submit="saveData">
              
              <div class="d-flex align-items-center mb-6">
                <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                  <input
                    type="checkbox"
                    name=""
                    v-model="enabled_select_payment"
                    @click="enabled_select_payment = !enabled_select_payment"
                  />
                  <span></span>
                </label>
                <span class="ml-3 cursor-pointer">{{ $t('PAGES.SETTINGS.ENABLE_SELECT_PAYMENT') }}</span>
              </div>

              <div v-if="enabled_select_payment">
                <!-- Stripe -->

                <div class="d-flex align-items-center mb-6">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      name=""
                      v-model="enabled_stripe"
                      @click="enabled_stripe = !enabled_stripe"
                    />
                    <span></span>
                  </label>
                  <span class="ml-3 cursor-pointer">{{ $t('SETTINGS.ENABLED_STRIPE') }}</span>
                </div>

                <b-card v-if="enabled_stripe" style="border: 1px solid #e9e9e9;" class="mb-8">
                  <h4>{{ $t('SETTINGS.STRIPE_SETTINGS') }}</h4>
                    <b-form-group
                      id="input-group-stripe_access_token"
                      :label="$t('SETTINGS.STRIPE_ACCESS_TOKEN')"
                      label-for="input-stripe_access_token"
                    >
                      <b-form-input
                        id="input-terms"
                        v-model.trim="stripe_access_token"
                        type="text"
                        :state="stripe_access_token_state"
                      ></b-form-input>
                      <b-form-invalid-feedback v-if="stripe_access_token === false">{{ $t('SETTINGS.INVALID_STRIPE_ACCESS_TOKEN') }}</b-form-invalid-feedback>
                      <b-form-text>sk_live_51LpW4AK...</b-form-text>
                    </b-form-group>

                    <b-form-group
                      id="input-group-stripe_pk_token"
                      :label="$t('SETTINGS.STRIPE_PK_TOKEN')"
                      label-for="input-stripe_pk_token"
                    >
                      <b-form-input
                        id="input-terms"
                        v-model.trim="stripe_pk_token"
                        type="text"
                        :state="stripe_pk_token_state"
                      ></b-form-input>
                      <b-form-invalid-feedback v-if="stripe_access_token === false">{{ $t('SETTINGS.INVALID_STRIPE_PK_TOKEN') }}</b-form-invalid-feedback>
                      <b-form-text>pk_live_51LpW4AK...</b-form-text>
                    </b-form-group>
                  
                  </b-card>

                <!-- Swish -->

                <div class="d-flex align-items-center mb-6">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      name=""
                      v-model="enabled_swish"
                      @click="enabled_swish = !enabled_swish"
                    />
                    <span></span>
                  </label>
                  <span class="ml-3 cursor-pointer">{{ $t('SETTINGS.ENABLED_SWISH') }}</span>
                </div>

                <b-card v-if="enabled_swish" style="border: 1px solid #e9e9e9;" class="mb-8">
                  <h4>{{ $t('SETTINGS.SWISH_SETTINGS') }}</h4>

                  <b-form-group
                    id="input-group-payee_alias"
                    :label="$t('SETTINGS.SWISH_PAYEE_ALIAS')"
                    label-for="input-payee_alias"
                  >
                    <b-form-input
                      id="input-terms"
                      v-model.trim="swish_payee_alias"
                      type="text"
                      :state="payee_alias_check"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="payee_alias_check === false">{{ $t('SETTINGS.INVALID_SWISH_PAYEE_ALIAS') }}</b-form-invalid-feedback>
                  </b-form-group>

                  <RightSaveButton
                    v-if="swish_status !== 'OPERATIONAL'"
                    ref="swish-test-button"
                    :text="$t('SETTINGS.VERIFY_SWISH_SETTINGS')"
                    @clicked="test_swish_clicked()"
                  />

                  <div v-else>
                    <p style="color: #1BC5BD; font-weight: bold;">{{ $t('SETTINGS.SWISH_OPERATIONAL') }}</p>
                  </div>
                  
                </b-card>

                
              </div>

              <h4>{{ $t('SETTINGS.INVOICE_PAYMENTS') }}</h4>

              <div>
                <b-form-group
                  id="input-group-payment_days"
                  :label="$t('PAGES.SETTINGS.PAYMENT_DAYS')"
                  label-for="input-payment_days"
                >
                  <b-form-input
                    id="input-payment_days"
                    v-model.trim="payment_days"
                    type="number"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-terms"
                  label="Betalningsvillkor"
                  label-for="input-terms"
                >
                  <b-form-input
                    id="input-terms"
                    v-model.trim="payment_terms"
                    type="text"
                    :state="payment_terms_check"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!payment_terms_check">Måste vara färre än 40 tecken</b-form-invalid-feedback>
                </b-form-group>
              </div>

              <b-card title="OCR inställningar" class="mb-8">

                <div class="d-flex align-items-center mb-6">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      name=""
                      v-model="checklen"
                      @click="checklen = !checklen"
                    />
                    <span></span>
                  </label>
                  <span class="ml-3 cursor-pointer">Längdsiffra</span>
                </div>


                <div class="d-flex align-items-center mb-6">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      name=""
                      v-model="ocr_default"
                      @click="ocr_default = !ocr_default"
                    />
                    <span></span>
                  </label>
                  <span class="ml-3 cursor-pointer">Löpnummer</span>
                </div>


                <div class="d-flex align-items-center mb-6">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      name=""
                      v-model="public_id"
                      @click="public_id = !public_id"
                    />
                    <span></span>
                  </label>
                  <span class="ml-3 cursor-pointer">Medlemsnummer</span>
                </div>

                <div class="d-flex align-items-center mb-6">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      name=""
                      disabled
                      v-model="checksum"
                      @click="checksum = !checksum"
                    />
                    <span></span>
                  </label>
                  <span class="ml-3 cursor-pointer">Checksiffra</span>
                </div>
                
              </b-card>

              <RightSaveButton
                ref="save-button"
                :text="$t('COMMON.SAVE')"
                @clicked="submit_clicked()"
              />
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";
</style>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';


export default {
  name: 'PaymentSettings',
  components: {
    RightSaveButton
  },
  props: ['company'],
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    payment_terms_check() {
      return (this.payment_terms+'').length < 40;
    },
    payee_alias_check() {
      if (this.swish_payee_alias === null) {
        return null;
      }

      return !isNaN(parseInt(this.swish_payee_alias));
    },
    stripe_pk_token_state() {
      if (this.stripe_pk_token === null) { return null; }
      // check format pk_live_ ...
      return this.stripe_pk_token.match(/^(pk_live|pk_test)/) ? true : false;
    },
    stripe_access_token_state() {
      if (this.stripe_access_token === null) { return null; }
      // check format sk_live_ ...
      return this.stripe_access_token.match(/^(sk_live|sk_test)/) ? true : false;
    }
  },
  mounted() {
    this.allowUpdate = false;

    if (this.settings.ocr_format) {
      this.checklen = this.settings.ocr_format.indexOf('checklen') >= 0;
      this.public_id = this.settings.ocr_format.indexOf('public_id') >= 0;
    }
    else {
      this.ocr_default = true;
    }

    if (!this.public_id) {
      this.ocr_default = true;
    }
    

    this.load_data();

    var that = this;

    setTimeout(function(){
      that.allowUpdate = true;
    }, 300);
    
  },
  data() {
    return {
      settings: {},

      enabled_select_payment: false, // ml_company_settings

      enabled_stripe: false, // ml_company_settings
      stripe_pk_token: null,
      stripe_access_token: null,

      enabled_swish: false, // ml_company_settings
      swish_payee_alias: null,
      swish_status: null,

      enabled_event_dintero: true,
      allowUpdate: false,
      ocr_format: '',
      ocr_default: true,
      public_id: false,
      checksum: true,
      checklen: false,

      profile_id: '',
      paymentOption: 'dintero',
      payment_days: 30,
      payment_terms: '',
      api_key_id: null
    };
  },
  watch: {
    
    ocr_default(newValue, oldValue) {
      if (newValue) {
        this.public_id = false;
      }

      this.setFormat();
    },
    public_id(newValue, oldValue) {
      if (newValue) {
        this.ocr_default = false;
      }

      this.setFormat();
    },
    checklen(newValue, oldValue) {
      this.setFormat();
    },
    
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.load_data();
      }
    }
  },
  methods: {

    async test_swish_clicked() {
      try {

        await this.submit_clicked();

        const res = await axios.post(`/swish/customer/test`);

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('SETTINGS.SWISH_TEST_SUCCESS'));

          this.swish_status = res.data.merchant.status;

          this.$refs['swish-test-button'].stop();
          return;
        }
      }
      catch (err) {
        console.error('test_swish_clicked error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SETTINGS.SWISH_TEST_FAILURE'));

      this.$refs['swish-test-button'].stop();
    },

    async submit_clicked() {

      await this.saveData();
    },
    
    setFormat() {
      
      this.ocr_format = `${(this.public_id?'public_id':'')}:${(this.checklen?'checklen':'')}:${(this.ocr_default?'default':'')}:${(this.checksum?'checksum':'')}`;

      if (this.allowUpdate) {
        this.saveSettings();
      }
    },

    async load_swish_customer() {
      try {
        const res = await axios.get(`/swish/customer`);

        if (res.status === 200) {
          this.swish_payee_alias = res.data.payee_alias;
          this.swish_status = res.data.status;
          this.enabled_swish = res.data.enabled;
        }
      }
      catch (err) {
        console.error('load_swish_customer', err);
      }
    },

    async load_stripe_customer() {
      try {
        const res = await axios.get(`/stripe/customer`);

        if (res.status === 200) {
          this.stripe_access_token = res.data.access_token;
          this.stripe_pk_token = res.data.pk;
          this.enabled_stripe = res.data.enabled;
        }
      }
      catch (err) {
        console.error('load_swish_customer', err);
      }
    },

    async load_data() {
      if (!this.currentCompanyId) {
        return;
      }

      await this.load_swish_customer();
      await this.load_stripe_customer();

      try {
        const res = await axios.get(`/company/company_settings/${this.currentCompanyId}`);

        if (res.status !== 200) {
          return;
        }

        this.settings = res.data;
      }
      catch (err) {
        console.error('load_data', err);
      }

      if (this.settings.enable_dintero) {
        this.paymentOption = 'dintero';
      } else if (this.settings.enable_invoices) {
        this.paymentOption = 'invoices';
      } else {
        this.paymentOption = '';
      }

      this.payment_days = this.settings.payment_days;
      this.payment_terms = this.settings.payment_terms;

      this.enabled_event_dintero = this.settings.enabled_event_dintero;
      this.enabled_select_payment = this.settings.enabled_select_payment;
      
      this.ocr_format = this.settings.ocr_format;

      this.checklen = this.settings.ocr_format.indexOf('checklen') >= 0;
      this.public_id = this.settings.ocr_format.indexOf('public_id') >= 0;
      
    },
    getData() {
      return {
        company_id:             this.currentCompanyId,
        enable_dintero:         this.paymentOption === 'dintero' ? 1 : 0,
        enable_invoices:        this.paymentOption === 'invoices' ? 1 : 0,
        payment_days:           this.payment_days,
        payment_terms:          this.payment_terms,
        ocr_format:             this.ocr_format,
        enabled_event_dintero:  this.enabled_event_dintero,
        enabled_select_payment: this.enabled_select_payment
      };
    },
    
    async saveSettingsAsync() {
      const data = this.getData();
      return axios.put(`/company/company_settings/${data.company_id}`, data)
    },

    async saveSettings() {
      const data = this.getData();

      await this.save_swish_settings();
      await this.save_stripe_settings();

      await axios.put(`/company/company_settings/${data.company_id}`, data)
      .then(res => {
        this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
      })
      .catch(err => {
        console.error(err);
      });
    },

    async save_swish_settings() {
      const payload = {
        enabled: this.enabled_swish,
        swish_payee_alias: this.swish_payee_alias
      }

      const res = await axios.put(`/swish/customer`, payload);

      if (res.status === 200) {
        console.log('swish update ok');
      }
      else {
        console.error('swish update error', res.data);
      }
    },

    async save_stripe_settings() {
      const payload = {
        enabled: this.enabled_stripe,
        pk: this.stripe_pk_token,
        access_token: this.stripe_access_token
      }

      const res = await axios.put(`/stripe/customer`, payload);

      if (res.status === 200) {
        console.log('stripe update ok');
      }
      else {
        console.error('stripe update error', res.data);
      }
    },


    async saveData(e) {
      if (e) {
        e.preventDefault();
      }
      
      if (!this.payment_terms_check) {
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Måste vara färre än 40 tecken');
        this.$refs['save-button'].stop();
        return;
      }

      const swish = await this.save_swish_settings();
      const stripe = await this.save_stripe_settings();

      const res = await this.saveSettingsAsync();

      if (res.status === 200) {
        this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
        this.$refs['save-button'].stop();
      }
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        this.$refs['save-button'].stop();
      }
      
    },

  }
};
</script>
